/* blob spin on about icon */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blue-back{
  background:url(../../images/blue-back.svg);
  width:140px;
  background-size:contain;
  background-repeat:no-repeat;
  margin:0 auto;
  text-align:center;
  padding-bottom:20px;
}

.z-top{
  z-index:1;
  text-align:center;
  margin:40px auto;
  margin-bottom:20px;
  height:100%;
  width:100%;
}

.blob{
    -webkit-animation: spin 140s infinite linear;
  -webkit-animation: spin 140s infinite linear;
  -ms-animation: spin 140s infinite linear;
  -o-animation: spin 140s infinite linear;
}

img.blob {
  height:70px;
  width:70px;
  margin:35px;
}