.footer {
  background: $accent-color;
}

.contact {
  display: block;
  max-width: 1080px;
  margin: 0 auto;
}

.form-wrapper {
  padding: 0 1em;
  max-width: 370px;
}

form {
  padding: 0em;
  margin:0 auto;
}

label {
  color: $primary-color;
  line-height: 1.6;
}

.form-item {
  width: 100%;
  margin-bottom: 2em;
}

.form-item input, .form-item textarea {
  background: $white;
  border: none;
  border-bottom: 1px solid #e9e9e9;
  color: mix($accent-color, $primary-color, $mix-weight);
  font-family: $accent-font-stack;
  height: 50px;
  transition: border-color 0.3s;
  padding:5px;
  width:100%;
  @include fluid-type(14px, 18px);
  cursor:text;
}

.name-input, .email-input{
  width:48%;
  display:inline;
}

.name-input{
  float:left;
}

.email-input{
  float:right;
}

.form-item textarea{
  height:100px;
  width: 100%;
}

.form-item input:focus, .form-item textarea:focus {
  border-bottom: 2px solid #c0c0c0;
  outline: none;
}

.button-panel {
  margin:0 auto;
  width: 100%;
}

.button-panel .button {
  background: $primary-color;
  border: none;
  color: $white;
  cursor: pointer;
  height: 100%;
  font-family: $primary-font-stack;
  @include fluid-type(16px, 24px);
  line-height:2em;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  -webkit-transition: 0.5s;
   transition: 0.5s;
  box-shadow: 0 4px 4px rgba(0,0,0,.24),0 0 4px rgba(0,0,0,.12);
  margin:0 0 4% 0;
}

.button:hover {
  transition: background .2s;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  -webkit-transform: translatey(-1px);
  transform: translatey(-1px);
  cursor: pointer; 
    -webkit-transition: 0.5s;
   transition: 0.5s;
}

.contact-message {
  display: none;
}