/* OTHER STYLES */
body {
  background-color: #fff;
  display: flex;
  justify-content: center;
  &.is-searching {
    &:after {
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      background: yellow;
      opacity: 0.4;
      width: 100%;
      height: 100%;
    };
  }
}

.container {
  display: grid;
  width:100%;
  margin-top: 4rem;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;
  grid-template-areas:
    "header header"
    "about about"
    "footer footer";
}

.header {
  grid-area: header;
}

.about {
  grid-area: about;
}

.footer {
  grid-area: footer;
  display: block;
}

.header,
.about,
.content-2,
.content-3{
  max-width: 1080px;
  margin: 0 auto;
  display: block;
}
