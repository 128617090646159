// Import Fonts
@import url('https://fonts.googleapis.com/css?family=Josefin+Slab:400,300,100,600');
@import url('https://fonts.googleapis.com/css?family=Vollkorn');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,700');

//Fluid Type
@mixin fluid-type(
    $min-font-size: 12px, 
    $max-font-size: 21px, 
    $lower-range: 420px, 
    $upper-range: 900px) {

    font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));

    @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range){
    font-size: $max-font-size;
    }
}

// Main Typography Variables
// Because we are displaying this value on page, there are no quotes around font names
$primary-font: Josefin Slab;
$accent-font: Vollkorn;

// Font Stacks
// You may need to change your stack based on the fonts you choose
$primary-font-stack: '#{$primary-font}', serif;
$accent-font-stack: '#{$accent-font}', serif;

// Get the font names
// The quotes around the font names are there in case the font name has multiple words

strong.primary-font:before,
.type-specimen.primary-font h2:before {
    content: '#{$primary-font}';
}
.primary-font {
    h1, h2, h3, h4, h5, h6, p, span, a{
    font-family: $primary-font-stack;
    }
}

h1, h2, h3,
h4, h5, h6 {
    -webkit-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
    -moz-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
    -o-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
    color: $primary-color;
    line-height: 1.15em;
    margin: 0 0 0.4em 0;
    font-family: $primary-font-stack;
    text-rendering: geometricPrecision;
}

h1 {
    @include fluid-type(36px, 68px);
    letter-spacing: -2px;
    text-indent: -3px;
}

h2 {
    @include fluid-type(36px, 48px);
    letter-spacing: -1px;
}

h3 {
    @include fluid-type(26px, 38px);
}

h4 {
    @include fluid-type(28px, 32px);
}

h5 {
    @include fluid-type(20px, 28px);
}

h6 {
    @include fluid-type(18px, 26px);
}

a {
    color: $tertiary-color;
    transition: color 0.3s ease;
    &:hover{
      color: mix($tertiary-color, $primary-color, $mix-weight);
    }
}

span a, span p, p a{
    font-family: $accent-font-stack;
}

span a:hover, p a:hover{
    color: mix($tertiary-color, $primary-color, $mix-weight);
}

p, ul, ol, dl, span {
    -webkit-font-feature-settings: 'liga' 1, 'onum' 1, 'kern' 1;
    -moz-font-feature-settings: 'liga' 1, 'onum' 1, 'kern' 1;
    -o-font-feature-settings: 'liga' 1, 'onum' 1, 'kern' 1;
    margin: 0 0 1.75em 0;
    text-rendering: geometricPrecision;
    font-family: $accent-font-stack;
    @include fluid-type(18px, 24px);
    line-height: 1.6;
    font-weight: 300;
}

ol, ul {
    padding-left: 3rem;
}

ol ol, ul ul,
ul ol, ol ul {
    margin: 0 0 0.4em 0;
    padding-left: 2em;
}

li {
    margin: 0.4em 0;
}

li li {
    margin: 0;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: #EFEFEF 1px solid;
    margin: 3.2em 0;
    padding: 0;
}

//customs

.name {
    line-height: 1;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 10px;
    display:block;
    font-weight: 700;
}

.title {
  	color:$primary-color;
    line-height: 1.6;
    text-align:center;
    display:block;
    margin: 10px;
}

a .title:hover{
    text-decoration: none;
    color:$primary-color;
}
