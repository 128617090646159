// Main Color Variables

$neutral-color: #ddd;
$white: #ffffff;

$primary-color: #343B3E;
$primary-color-name: 'Onyx';

$secondary-color: #00CBFF; 
$secondary-color-name: 'Sky Blue';

$tertiary-color: #246BF0; 
$tertiary-color-name: 'Soap blue';

$accent-color: #f5f8fa;
$accent-color-name: 'Mint Cream';

// How strong should the added color be in the Mixed Colors?
// Closer to 0% = closer to original base color
$mix-weight: 65%;